.item-card-gallery-card {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.item-card-image {
  top: 0px;
  flex: 1;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  object-fit: cover;
}
.item-card-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.item-card-text {
  color: var(--dl-color-gray-black80);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: var(--dl-space-space-unit);
}
.item-card-container1 {
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: repeat(5,1fr);
}
.item-card-icon {
  fill: var(--dl-color-pallet-yellow);
  width: 24px;
  height: 24px;
}
.item-card-icon02 {
  fill: var(--dl-color-pallet-yellow);
  width: 24px;
  height: 24px;
}
.item-card-icon04 {
  fill: var(--dl-color-pallet-yellow);
  width: 24px;
  height: 24px;
}
.item-card-icon06 {
  fill: var(--dl-color-pallet-yellow);
  width: 24px;
  height: 24px;
}
.item-card-icon08 {
  fill: var(--dl-color-pallet-yellow);
  width: 24px;
  height: 24px;
}
.item-card-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.item-card-currency {
  color: var(--dl-color-gray-black50);
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
}
.item-card-value {
  color: var(--dl-color-gray-black50);
  font-style: normal;
  font-weight: 500;
}
.item-card-root-class-name {
  flex: 1;
}



.item-card-root-class-name4 {
  flex: 1;
  height: 100%;
}


@media(max-width: 767px) {
  .item-card-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .item-card-image {
    top: 0px;
    left: 0px;
    right: auto;
    bottom: auto;
  }
}
