.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  padding-top: 70px;
  flex-direction: column;
}
.home-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-pallet-green);
}
.home-header {
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.home-container01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-image {
  width: 24px;
  height: 24px;
  z-index: 100;
  margin-right: -32px;
}
.home-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.home-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.home-text {
  font-family: "Arial";
}
.home-text01 {
  font-family: "Arial";
}
.home-text02 {
  font-family: "Arial";
}
.home-logo-center {
  width: var(--dl-size-size-xxlarge);
  font-size: 22px;
  font-family: "Arial";
}
.home-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.home-text03 {
  font-family: "Arial";
}
.home-text04 {
  font-family: "Arial";
}
.home-text05 {
  font-family: "Arial";
}
.home-icons {
  display: flex;
}
.home-image1 {
  width: 24px;
  height: 24px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.home-image2 {
  width: 24px;
  height: 24px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo-center1 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.home-text06 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.home-text07 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.home-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.home-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.home-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.home-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-hero {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-pallet-green);
}
.home-max-width {
  max-width: 1032px;
}
.home-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container03 {
  display: flex;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-info {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-image3 {
  width: 2px;
  height: 52px;
  margin: 0 14px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text12 {
  color: var(--dl-color-gray-black50);
  font-size: 12px;
  text-align: left;
  font-family: "Arial";
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
}
.home-text13 {
  font-size: 18px;
}
.home-text15 {
  max-width: 650px;
  min-width: 400px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container04 {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
}
.home-text19 {
  color: var(--dl-color-gray-black80);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-right: 4px;
  letter-spacing: 0.1em;
}
.home-text20 {
  color: var(--dl-color-gray-black80);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-image4 {
  width: 534px;
  position: relative;
  max-width: 579px;
  box-sizing: border-box;
  max-height: 579px;
  object-fit: cover;
  border-color: transparent;
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  max-width: 1080px;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-950);
}
.home-container05 {
  flex: 0 0 auto;
  width: 350px;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-950);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-text21 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text24 {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 1em;
  text-transform: uppercase;
}
.home-container06 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container07 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 770px;
  box-sizing: border-box;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  flex-direction: column;
}
.home-text27 {
  color: var(--dl-color-gray-black80);
  width: 100%;
  height: auto;
  font-size: 16px;
  align-self: auto;
  text-align: center;
  font-family: "Arial";
  font-weight: 400px;
  line-height: 175%;
  font-stretch: normal;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.home-text28 {
  color: var(--dl-color-gray-black);
  font-family: "Arial";
  font-weight: 700;
  text-transform: uppercase;
}
.home-text29 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text30 {
  font-style: normal;
}
.home-text31 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text32 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text33 {
  font-style: normal;
}
.home-text34 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text35 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text36 {
  font-style: normal;
}
.home-text37 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text38 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text39 {
  font-style: normal;
}
.home-text40 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text41 {
  font-style: normal;
}
.home-text42 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text43 {
  font-style: normal;
}
.home-text44 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text45 {
  font-style: normal;
}
.home-text46 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: "Arial";
  text-transform: uppercase;
}
.home-text47 {
  font-style: normal;
}
.home-svg {
  top: -30px;
  width: 199px;
  height: 200px;
  position: absolute;
}
.home-gallery {
  width: 100%;
  display: flex;
  align-items: stretch;
}
.home-left1 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-right1 {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.home-top {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.home-left2 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-right2 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-bottom {
  flex: 1;
  width: 100%;
  display: flex;
}
.home-left3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-right3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-full-width-banner {
  width: 100%;
  display: flex;
  align-items: stretch;
  padding-top: var(--dl-space-space-fourunits);
  justify-content: center;
}
.home-left4 {
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
  background-color: #F3F0E6;
}
.home-content {
  margin: 0 0 40px 0;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  flex-direction: column;
}
.home-text48 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  margin: 0 0 16px 0;
  font-size: 28px;
  align-self: auto;
  font-style: normal;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500px;
  line-height: 20px;
  font-stretch: normal;
  text-decoration: none;
}
.home-text49 {
  color: rgba(0, 0, 0, 0.6000000238418579);
  width: 267px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: normal;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400px;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-btn {
  cursor: pointer;
  display: flex;
  padding: 16px 24px;
  position: relative;
  box-sizing: border-box;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(239, 193, 76, 1);
}
.home-btn:hover {
  background-color: var(--dl-color-gray-white);
}
.home-text50 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: normal;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500px;
  line-height: 14px;
  font-stretch: normal;
  text-decoration: none;
}
.home-image5 {
  width: 60%;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-950);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.home-container09 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.home-text51 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text52 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.home-text55 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-twounits);
}
.home-text56 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.home-links-container {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container10 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text57 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.home-text58 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text59 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text60 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text61 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text62 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text63 {
  color: var(--dl-color-gray-black50);
}
.home-container11 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text64 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.home-text65 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text66 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text67 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text68 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text69 {
  color: var(--dl-color-gray-black50);
}
.home-container12 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text70 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.home-text71 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text72 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text73 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text74 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .home-container01 {
    display: none;
  }
  .home-left {
    display: none;
  }
  .home-right {
    display: none;
  }
  .home-icons {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-icon {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .home-logo-center1 {
    margin-left: 0px;
  }
  .home-hero1 {
    flex-direction: column;
  }
  .home-container03 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text15 {
    text-align: center;
  }
  .home-left1 {
    width: 100%;
  }
  .home-footer1 {
    flex-direction: column-reverse;
  }
  .home-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-main {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-cards-container {
    grid-gap: var(--dl-space-space-unit);
  }
  .home-text27 {
    max-width: 770px;
  }
  .home-gallery {
    flex-direction: column;
  }
  .home-top {
    flex-direction: column;
  }
  .home-bottom {
    flex-direction: column;
  }
  .home-full-width-banner {
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-left4 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-content {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text49 {
    width: auto;
  }
  .home-image5 {
    width: 100%;
  }
  .home-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .home-container {
    padding-top: 55px;
  }
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-left4 {
    width: auto;
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container10 {
    margin-right: var(--dl-space-space-unit);
  }
  .home-container11 {
    margin-right: var(--dl-space-space-unit);
  }
}
